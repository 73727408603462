import React from 'react'

const Contact = () => {
  return (
    <div className="contact-page">
          <p>Thank you for stopping by</p><br/>
          <p>If you have any feedback, questions or concerns send us an email:</p><br/>
          <p>info@ceevee.co</p>
    </div>
  )
}

export default Contact