import Review1 from '../images/Reviews/Review1.png'
import Review2 from '../images/Reviews/Review2.png'
import Review3 from '../images/Reviews/Review3.png'
import Review4 from '../images/Reviews/Review4.png'
import Review5 from '../images/Reviews/Review5.png'
import Review6 from '../images/Reviews/Review6.png'
import Review7 from '../images/Reviews/Review7.png'
import Review8 from '../images/Reviews/Review8.png'
import Review9 from '../images/Reviews/Review9.png'
import Review10 from '../images/Reviews/Review10.png'

const Data = [
    {
    id:1,
    img:Review1,
    content:"This Ceevee helped me to getting into top notch comapnies."
    },
    {
    id:2,
    img:Review2,
    content:"This Ceevee helped me to getting into top notch comapnies."
    },
    {
    id:3,
    img:Review3,
    content:"This Ceevee helped me to getting into top notch comapnies."
    },
    {
    id:4,
    img:Review4,
    content:"This Ceevee helped me to getting into top notch comapnies."
    },
    {
    id:5,
    img:Review5,
    content:"This Ceevee helped me to getting into top notch comapnies."
    },
    {
        id:6,
        img:Review6,
        content:"This Ceevee helped me to getting into top notch comapnies."
    },
    {
        id:7,
        img:Review7,
        content:"This Ceevee helped me to getting into top notch comapnies."
    },
    {
        id:8,
        img:Review8,
        content:"This Ceevee helped me to getting into top notch comapnies."
    },
    {
        id:9,
        img:Review9,
        content:"This Ceevee helped me to getting into top notch comapnies."
    },
    {
        id:10,
        img:Review10,
        content:"This Ceevee helped me to getting into top notch comapnies."
    },
]


export default Data